import React from 'react';
import footerStyles from '../styles/footer.module.css'
import '../styles/fontawesome-free-5.14.0-web/css/all.css'
import InfoBox from './info-box.js'
import { Link, graphql, StaticQuery } from 'gatsby';
// import footerElements from './footer-elements'

export default class ScoialLinks extends React.Component {

    getSocialLinks(data) {
        let linkData = data.allMarkdownRemark.edges;
        let links = [];
        let i = 0;
        for (let link of linkData) {
            i++
            links.push(<a key={i} href={link.node.frontmatter.link}><span className={`fab fa-${link.node.frontmatter.icon} ${footerStyles.socialIcon}`}></span></a>)
        }
        return links;
    }
    
    render() {
        return(
            <div className={footerStyles.socials}>

        
                        <StaticQuery query={
                            graphql`
                                query {
                                    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(social)/"}}) {
                                    edges {
                                        node {
                                        frontmatter {
                                            icon
                                            link
                                        }
                                        }
                                    }
                                    }
                                }
                                `
                            } render={data => (
                                <span>
                                    {this.getSocialLinks(data)}
                                </span>
                        )} />  
        </div>
        )
    }      
}

