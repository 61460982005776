import { StaticQuery } from "gatsby";
import React, { useState } from "react";
import { render } from "react-dom";
import styles from '../styles/banner.module.css'

export default function Banner(props) {
    
    const generateBanners = (data) => {

        const bannersData = data.allMarkdownRemark.edges;

        const bannerItems = []

        bannersData.forEach((element, index) => {

            if (element.node.frontmatter.enabled) {
                const words = element.node.frontmatter.description.split(' ')
                let description = [];
    
                words.forEach(word => {
                    if (word.includes("--link/")) {
                        const link = word.replace("--link/", ";");
                        const splittedString = link.split(";");
                        description.push(splittedString[0]) 
                        description.push(<a href={splittedString[1]}>{splittedString[1].replace("https://", "")}</a>) 
                        description.push(splittedString[2]) 
                        description.push(" ")
                    } else if (word.includes("--newline")) {
                        description.push(<br></br>)
                    } else {
                        description.push(word + ' ')
                    }
                })
                
    
                bannerItems.push(
                <div key={index} className={styles.banner}>
                    <div className={styles.bannerInner}>
                        <span className={`fa fa-${element.node.frontmatter.icon} ${styles.bannerIcon}`}></span>
                        <span className={styles.bannerIcondesc}>{description}</span>
                    </div>
                </div>)
            }
            
        });

        return bannerItems;
    }

    return (
        <div className={styles.bannerContainer}>

        <StaticQuery
            query={graphql`
                    query {
                        allMarkdownRemark(filter: {frontmatter: {type: {eq: "banner"}}}) {
                        edges {
                            node {
                            frontmatter {
                                description
                                enabled
                                type
                                icon
                            }
                            rawMarkdownBody
                            }
                        }
                        }
                    }
                    `
            
        } render={data => (
            <span>{generateBanners(data)}</span>
            )}
        />
        </div>
    )
}