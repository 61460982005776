import React from 'react'
import Helment from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby';

export default function Seo(description, title, url, author, keywords) {
    return (
        <StaticQuery query={
            graphql`
    query {
        site {
            siteMetadata {
                title,
                description,
                siteUrl,
                author,
                keywords
            }
        }
    }
`
        }
            render={(data) => {
                const metaDescription = data.site.siteMetadata.description
                const metaTitle = data.site.siteMetadata.title
                const metaUrl = data.site.siteMetadata.siteUrl
                const metaKeywords = data.site.siteMetadata.keywords
                return (
                    <Helment
                        title={metaTitle}
                        meta={[
                            {
                                name: 'description',
                                content: metaDescription
                            },
                            {
                                name: 'og:title',
                                content: metaTitle
                            },
                            {
                                name: 'og:description',
                                content: metaDescription
                            },
                            {
                                name: 'og:type',
                                content: 'website'
                            },
                            {
                                name: 'og:url',
                                content: metaUrl
                            }
                        ].concat(
                            metaKeywords && metaKeywords.length > 0 ? {
                                name: 'keywords',
                                content: metaKeywords.join(', ')
                            } : []
                        )                        
                        } />
                )
            }}
        
        />
    )
}