import React from 'react';
import footerStyles from '../styles/footer.module.css'
import '../styles/fontawesome-free-5.14.0-web/css/all.css'
import InfoBox from './info-box.js'
import { Link, graphql, StaticQuery } from 'gatsby';
import ScoialLinks from './social-links';
// import footerElements from './footer-elements'

export default class Footer extends React.Component {

    date = new Date();

    getFooterElemens(footerElements) {
        let footerData = this.sortFooter(footerElements.allMarkdownRemark.edges);
        let elements = [];
        let i = 0;
        for (let element of footerData) {
            i++
            elements.push(<InfoBox key={i} info={element.node.frontmatter}/>)
        }
        return elements;
    }

    getSocialLinks(data) {
        let linkData = data.allMarkdownRemark.edges;
        let links = [];
        let i = 0;
        for (let link of linkData) {
            i++
            links.push(<a href={link.node.frontmatter.link}><span className={`fab fa-${link.node.frontmatter.icon} ${footerStyles.socialIcon}`}></span></a>)
        }
        return links;
    }

    sortFooter(footerData) {
        return footerData.sort((a, b) => {
          return a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
        })
      }
    
    render() {
        return(
            <footer>
                <div className={footerStyles.footer}>
                    <div className={footerStyles.content}>
                        
                        <StaticQuery query={
                            graphql`
                                query {
                                    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(footer)/"}}) {
                                    edges {
                                        node {
                                        frontmatter {
                                            icon
                                            title
                                            lines {
                                                line
                                            }
                                            order
                                        }
                                        }
                                    }
                                    }
                                }
                                `
                            } render={data => (
                                <div className={footerStyles.iconsDescription}>
                                    {this.getFooterElemens(data)}
                                </div>
                        )} />
                    
                        <ScoialLinks></ScoialLinks> 
                        <div className={footerStyles.copyright}>
                            © {this.date.getFullYear()} waldfreund.ch
                        </div>
                    </div>
                </div>
            </footer>
        )
    }      
}

