import React from 'react';
import Footer from './footer'
import Header from './header'
import layoutStyles from '../styles/layout.module.css'
import '../styles/global.css'
import Helmet from 'react-helmet' 
import Seo from './seo'
import Banner from './banner';

class Layout extends React.Component {
    render() {
        return(
            <div className={layoutStyles.pageLayout}>
                <Helmet>
                    <title>Waldfreund</title>
                </Helmet>
                <Seo />
                <Banner/>
                <Header />
                <div className={layoutStyles.container}>
                    <div className={layoutStyles.content}>
                        {this.props.children}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Layout