import React from 'react';
import '../styles/fontawesome-free-5.14.0-web/css/all.css'
import infoBoxStyles from '../styles/info-box.module.css'


class InfoBox extends React.Component {

    getRows() {
        let lines = [];
        let i = 0;
        for (let line of this.props.info.lines) {
            i++
            if (line.line.includes("--link/")) {
                const link = line.line.replace("--link/", ";");
                const splittedString = link.split(";");
                lines.push(<span key={i} className={infoBoxStyles.row + " " + infoBoxStyles.link}>{splittedString[0]}</span>) 
                i++
                lines.push(<a href={splittedString[1]} key={i} className={infoBoxStyles.row + " " + infoBoxStyles.link}>{splittedString[1].replace("https://", "")}</a>) 
                i++
                lines.push(<span key={i} className={infoBoxStyles.row + " " + infoBoxStyles.link}>{splittedString[2]}</span>) 
            } else {
                lines.push(<p key={i} className={infoBoxStyles.row}>{line.line}</p>)
            }
        }
        return lines;
    }

    render() {
        return (
            <div className={infoBoxStyles.infoBox}>
                <p className={infoBoxStyles.title} >{this.props.info.title}</p>
                <div className={infoBoxStyles.content}>
                    <div className={`fas fa-${this.props.info.icon} ${infoBoxStyles.icon}`}>
                    </div>
                    <div>
                        {this.getRows()}
                    </div>
                </div>
            </div>
        )
    }
} 

export default InfoBox