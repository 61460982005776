import React, {useEffect} from 'react';
import headerStyles from '../styles/header.module.css'
import {Link, graphql, StaticQuery} from 'gatsby';

export default function Header({data}) {

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    return (
        <StaticQuery query={
            graphql`
                        query {
                            allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(header)/"}}) {
                              edges {
                                node {
                                  frontmatter {
                                    link
                                    title
                                    order
                                  }
                                }
                              }
                            }
                          }
                          `
        } render={data => (
            <div>
                <div id="scroll-header" className={headerStyles.scrollHeaderNotDisplayed}>
                    <div>
                        <Link to="/"><img src="/assets/logo.png" alt="logo" className={headerStyles.logoMobile}/></Link>
                    </div>
                    <div>
                        <button className={headerStyles.hamburgerMenuScroll} onClick={openMobileHeader}>&#9776;</button>
                    </div>
                    <ul className={headerStyles.navList}>
                        {generateHeader(data)}
                    </ul>
                </div>
                <div className={headerStyles.hamburgerFlex}>
                    <button className={headerStyles.hamburgerMenu} onClick={openMobileHeader}>&#9776;</button>
                </div>
                <div id="mobile-menu" className={headerStyles.hideMobileMenu}>
                    <button className={headerStyles.closeBtn} onClick={closeMobileHeader}>&times;</button>
                    <div className={headerStyles.mobileItems}>
                        <div className={headerStyles.mobileFlex}>
                            {generateHeader(data)}
                        </div>
                    </div>

                </div>
                <div className={headerStyles.header} id="header">
                    <div className={headerStyles.logoBox}>
                        <Link to="/"><img src="/assets/logo.png" alt="logo" className={headerStyles.logo}/></Link>
                    </div>
                    <div>
                        <ul className={headerStyles.navList}>
                            {generateHeader(data)}
                        </ul>
                    </div>
                </div>
            </div>
        )}/>
    )
}


function generateHeader(data) {
    let headerData = sortHeader(data.allMarkdownRemark.edges);
    let key = 0;
    let headerContent = [];
    for (let data of headerData) {
        let object = data.node.frontmatter;
        key++;
        if (object.external)
        {
            headerContent.push(<li key={key}><a href={object.link}>{object.title}</a></li>)
        } else {
            headerContent.push(<li key={key}><Link activeClassName={headerStyles.activeLink}
                                                   to={object.link}>{object.title}</Link></li>)
        }
    }
    return headerContent;
}

function sortHeader(headerData) {
    return headerData.sort((a, b) => {
        return a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
    })
}

function handleScroll(event) {
    let headerHeight = document.getElementById("header").clientHeight;
    let scrollHeader = document.getElementById("scroll-header");
    if (window.scrollY > headerHeight) {
        scrollHeader.className = headerStyles.scrollHeader;
    } else if (window.scrollY < headerHeight) {
        if (!scrollHeader.classList.contains(headerStyles.scrollHeaderOut) && !scrollHeader.classList.contains(headerStyles.scrollHeaderNotDisplayed)) {
            scrollHeader.classList.add(headerStyles.scrollHeaderOut);
            setTimeout(() => {
                if (scrollHeader.classList.contains(headerStyles.scrollHeaderOut)) {
                    scrollHeader.className = headerStyles.scrollHeaderNotDisplayed;
                }
            }, 500)
        }
    }
}

function openMobileHeader() {
    document.getElementById("mobile-menu").className = headerStyles.mobileMenu;
}

function closeMobileHeader() {
    let mobileMenu = document.getElementById("mobile-menu");
    mobileMenu.classList.add(headerStyles.mobileMenuOut);
    setTimeout(() => {
        mobileMenu.className = headerStyles.hideMobileMenu;
    }, 1000)
}
